import Button from "@/components/atoms/Button";
import { ModalOptions } from "@/components/molecules";
import { ReactNode } from "react";
import openModal from "./openModal";

type TopenModalConfirmationFunction = (
  message: ReactNode,
  options?: Partial<ModalOptions>
) => Promise<boolean | undefined>;

const openModalConfirmation: TopenModalConfirmationFunction = (
  message,
  options
) => {
  return new Promise<boolean | undefined>((resolve, reject) => {
    openModal(
      (close) => (
        <div className="flex flex-col px-[45px]">
          <div className="mb-5">{message}</div>

          <Button
            addClassName="w-full"
            onClick={() => {
              resolve(true);
              close();
            }}
          >
            Ya
          </Button>

          <div
            className="cursor-pointer py-2"
            onClick={() => {
              // resolve(false)
              reject();
              close();
            }}
          >
            Tidak
          </div>
        </div>
      ),
      options
    );
  });
};

export default openModalConfirmation;
