import { Modal, ModalOptions } from "@/components/molecules";
import { v4 as uuid } from "uuid";
import React, { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/pages/_app";
import FocusTrap from "../components/templates/FocusTrap";

/* -------------------------------------------------------------------------- */
/*                            STYLED MODAL(HEADER)                            */
/* -------------------------------------------------------------------------- */
export type TopenModalFunction = (
  message: ReactNode | ((close: () => Promise<void>) => ReactNode),
  options?: Partial<ModalOptions>
) => Promise<void>;

export const openModal: TopenModalFunction = (message, options) => {
  // const container = document.getElementById('modal-root') as Element;
  const randId = uuid();
  var container = document.createElement("div");
  container.id = randId;
  container.style.cssText = "z-index: 9999";

  document.body.appendChild(container);

  const root = createRoot(container); // createRoot(container!) if you use TypeScript

  return new Promise<void>((resolve, reject) => {
    const close = () => {
      try {
        root.unmount();
        resolve();
        document.getElementById(randId)?.remove();
      } catch {
        root.unmount();
        document.getElementById(randId)?.remove();
        reject();
      }
    };

    root.render(
      <QueryClientProvider client={queryClient}>
        <FocusTrap>
          <Modal onClose={close} options={options} message={message} />
        </FocusTrap>
      </QueryClientProvider>
    );
    return;
  });
};

export default openModal;
