import { ReactNode, useEffect } from "react";

const FocusTrap = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    const parent = document.querySelector("#focus-trap-container");
    var focusableEls: any, firstFocusableEl: any, lastFocusableEl: any;

    if (!!parent) {
      focusableEls = parent.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      );
      firstFocusableEl = focusableEls[0];
      lastFocusableEl = focusableEls[focusableEls.length - 1];
    } else return;

    const trapFocus = (e: KeyboardEvent) => {
      if (e.key !== "Tab") return;

      // disable tab key if there is no focusable element inside modal
      if (e.key === "Tab" && focusableEls.length === 0) e.preventDefault();

      // prettier-ignore
      /* shift + tab */
      if (e.shiftKey && document.activeElement === firstFocusableEl) {
        (lastFocusableEl as HTMLElement)?.focus();
        e.preventDefault();
      }
      /* tab */ 
      else if (document.activeElement === lastFocusableEl) {
        (firstFocusableEl as HTMLElement)?.focus();
        e.preventDefault();
      }
    };

    (firstFocusableEl as HTMLElement)?.focus();
    document.addEventListener("keydown", trapFocus);
    return () => document.removeEventListener("keydown", trapFocus);
  }, []);

  return <div id="focus-trap-container">{children}</div>;
};

export default FocusTrap;
