import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "../components/atoms/Button";
import * as Yup from "yup";
import Input from "@/components/atoms/InputField";
import { useRouter } from "next/router";
import axios from "@/plugins/axios";
import useToken from "@/store/useToken";
import useAdminMe from "@/store/useAdminMe";
import * as Sentry from "@sentry/nextjs";
import { openModal } from "@/plugins";
import useAlreadyLogin from "@/hooks/useAlreadyLogin";

export default function LoginPage() {
  useAlreadyLogin("/homepage");
  const router = useRouter();
  const { setToken } = useToken();
  const { setAdminMe, statusAdmin, setStatusAdmin } = useAdminMe();
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append("password", values.password);

      axios
        .post("/admin/login", formData)
        .then(function (response) {
          axios({
            method: "post",
            url: "/admin/admins/me",
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
            },
          })
            .then(function (res) {
              console.log(res);
              setIsLoading(false)

              setAdminMe(res.data);
              setStatusAdmin("HAVE ADMIN ME");
              Sentry.setUser({
                id: res.data.id.toString(),
                email: res.data.email,
                username: res.data.name,
                segment: res.data.role,
              });

              setToken(response.data.access_token);
              router.push("/homepage");
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false)
            });
        })
        .catch((error) => {
          console.log(error.response);
          setIsLoading(false)
          if (error.response?.data?.detail?.msg === "username tidak terdaftar") {
            formik.setFieldError("username", "Email tidak terdaftar");
          } else if (error.response?.data?.detail?.msg === "kata sandi salah") {
            formik.setFieldError("password", "Password salah");
          } else if (error.response?.data?.detail?.msg === "akun tidak aktif") {
            formik.setFieldError("username", "Akun tidak aktif");
          } else if (error.response?.data?.detail?.msg){
            formik.setFieldError("username", error.response.data.detail.msg);
          }else {
            openModal(error.message)
          }
        });
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Email harus diisi").email("Email salah"),
      password: Yup.string()
        .required("Password harus diisi")
        .min(6, "Password minimal 6 karakter"),
    }),
  });

  return (
    <div className="min-h-screen bg-neutral-300">
      <div className="flex justify-center pt-[100px]">
        <div className="flex w-[375px] flex-col">
          <h1 className="text-center text-[42px] font-semibold text-white-100">
            Login Admin
          </h1>

          <form onSubmit={formik.handleSubmit}>
            <div className="mt-[50px]">
              <label
                className="block text-lg text-primary-main"
                htmlFor="username"
              >
                Email
              </label>
              <Input
                id="username"
                placeholder="Email"
                size="lg"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.errors.username && formik.touched.username
                    ? formik.errors.username
                    : undefined
                }
                onBlur={formik.handleBlur}
                isPositive={
                  !!formik.values.username &&
                  !formik.errors.username &&
                  !!formik.touched.username
                }
              />
            </div>

            <div className="mt-[20px]">
              <label
                className="block text-lg text-primary-main"
                htmlFor="password"
              >
                Password
              </label>
              <Input
                id="password"
                placeholder="Password"
                type="password"
                size="lg"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : undefined
                }
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="mt-[35px] text-center">
              <Button type="submit" size="lg" widthFull disabled={isLoading}>
                Masuk
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
