import useToken from "@/store/useToken";
import { useRouter } from "next/router";
import { useEffect } from "react";

const useAlreadyLogin = (customRedirect?: string) => {
  const { token } = useToken();
  const { push, back } = useRouter();

  useEffect(() => {
    if (token) {
      customRedirect ? push(customRedirect) : back();
    }
  }, []);
};

export default useAlreadyLogin;
