import { ModalForm } from "@/components/molecules"
import { FormikHelpers, FormikValues } from "formik";
import openModal from "./openModal"

export type TFormFieldModalFile = {
   name: string,
   type?: "file"
   maksimalUpload?: string | number
   title?: string
   description?: string
   accept?: string
   defaultValue?: string
}

export type TFormFieldModal = {
   name: string,
   placeholder: string
   type?: "input" | "textarea"
}

type TOpenModalFormFieldInit<T> = {

   onSuccess: (params: {
      value: T;
      helpers: FormikHelpers<T>;
      close: () => Promise<void>;
   }) => void

   listFields?: (TFormFieldModal | TFormFieldModalFile)[]
   /**
    * tittle
    */
   title?: string,

   initValue: T
   /**
    * placeholder dari input
    */
   buttonTitle?: string
   validationSchema?: any,
}

const openModalFormField = <T extends FormikValues>({
   onSuccess,
   validationSchema,
   listFields = [
      {
         placeholder: "Alasan unblokir akun",
         type: "textarea",
         name: "field"
      }
   ],
   buttonTitle, title,
   initValue,
}: TOpenModalFormFieldInit<T>) => {

   // const {
   //    onSuccess,
   //    validationSchema,
   //    listFields = [{ placeholder: "Alasan unblokir akun", type: "textarea", name: "field" }],
   //    buttonTitle, title,
   //    initValue,
   // } = params;

   openModal(
      close => {
         return (
            <ModalForm<T>
               initValue={initValue}
               listFields={listFields}
               validationSchema={validationSchema}
               onSubmit={({ helpers, value }) => onSuccess({
                  close,
                  helpers,
                  value
               })}
               buttonTitle={buttonTitle}
               title={title}
            />
         )
      }, {
      disableBackdropClose: true,
   }
   )
}

export default openModalFormField